.Map {
  width: 100%;
  height: 100%;
  position: fixed;
}

.BaseLayersControl {
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 1000;
}

.ol-zoom {
  bottom: 4em !important;
  top: unset !important;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  height: 31px;
  width: 100%;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.rtl {
  direction: rtl
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
